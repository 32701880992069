.NewsletterSignupWidget {
    --form-validation-border-ok: var(--dkform-validation-border-ok, #70e0af);
    --form-validation-border-err: var(--dkform-validation-border-err, #e35c5c);

    --form-input-ok-box-shadow-color: rgba(0, 0, 0, 0.07), 0 0 8px rgba(112, 224, 175, 0.6);
    --form-input-err-box-shadow-color: rgba(0, 0, 0, 0.07), 0 0 8px rgba(227, 92, 92, 0.6);

    --form-input-ok-box-shadow: inset 0 1px 1px var(--form-input-ok-box-shadow-color);
    --form-input-err-box-shadow: inset 0 1px 1px var(--form-input-err-box-shadow-color);

    .validated-true {
        border-color: var(--dkform-validation-border-ok);
        box-shadow: var(--form-input-ok-box-shadow);
    }

    .validated-false {
        border-color: var(--dkform-validation-border-err);
        box-shadow: var(--form-input-err-box-shadow);
    }

    .form-feedback {
        min-height: 20px;
    }
}

// a button that is inside a text field
//
// markup:
//
//    <div class="form-field-with-button">
//        <input type="text" placeholder="Enter your email address">
//        <button type="submit">Subscribe</button>
//    </div>
//
// dkforms:
//
//    {% load plainforms %}
//    {% form myform %}
//        <div class="form-field-with-button">
//            {% widget email %}
//            {% submit class="btn btn-primary" %}
//        </div>
//        <span class="form-feedback"></span>
//    {% endform %}
//


.form-field-with-button {
    --height: 3.5rem; // 56px
    --gap: .25rem; // 4px
    --font-size: 1.125rem; // 18px    
    --button-padding: 0.75rem; // 12px
    --button-border-width: .125rem; // 2px

    display: grid;
    grid-template-columns: 3fr max-content;
    gap: var(--gap);
    justify-items: space-around;

    input, button {
        grid-row: 1 / 2;
    }
    input { 
        grid-column: 1 / -1;
    }
    button { 
        transition: width 1s linear;
        height: calc(var(--height) - (2 * var(--gap)) - (2 * var(--button-border-width)));
        grid-column: 2 / -1;
        align-self: center;
        margin: var(--gap);
        padding-inline: var(--button-padding);
        font-size: var(--font-size);
    }
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 19.125px;
        height: 19.125px;
        margin: 2.25px;
        border: 2.25px solid #cef;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #cef transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
